<template>
  <div>
    <a-modal
      :visible="visible"
      title="编辑"
      :width="640"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      :maskClosable="false"
      @cancel="handleCancel"
      @ok="handleConfirm"
    >
      <a-form :form="dataForm">
        <a-row :gutter="36">
          <a-col :span="12">
            <a-form-item label="名称">
              <a-input
                v-decorator="[
                  'name',
                  {
                    initialValue: item.name,
                    rules: [
                      { required: true, message: '请输入名称' },
                      { max: 64, message: '超出最大长度(64)' },
                    ],
                  },
                ]"
                :allowClear="true"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="备注">
              <a-input
                v-decorator="['remark', { initialValue: item.remark, rules: [{ max: 256, message: '超出最大长度(256)' }] }]"
                :allowClear="true"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="key">
              <a-input
                v-decorator="[
                  'key',
                  {
                    initialValue: item.key,
                    rules: [
                      { required: true, message: '请输入 key' },
                      { max: 256, message: '超出最大长度(256)' },
                    ],
                  },
                ]"
                :allowClear="true"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="destination_location_id">
              <a-input
                v-decorator="[
                  'destination_location_id',
                  {
                    initialValue: item.destination_location_id,
                    rules: [
                      { required: true, message: '请输入 destination_location_id' },
                      { max: 256, message: '超出最大长度(256)' },
                    ],
                  },
                ]"
                :allowClear="true"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="password">
              <a-input
                v-decorator="[
                  'password',
                  {
                    initialValue: item.password,
                    rules: [
                      { required: true, message: '请输入 password' },
                      { max: 256, message: '超出最大长度(256)' },
                    ],
                  },
                ]"
                :allowClear="true"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="device_id">
              <a-input
                v-decorator="[
                  'device_id',
                  {
                    initialValue: item.device_id,
                    rules: [
                      { required: true, message: '请输入 device_id' },
                      { max: 256, message: '超出最大长度(256)' },
                    ],
                  },
                ]"
                :allowClear="true"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="location_id">
              <a-input
                v-decorator="[
                  'location_id',
                  {
                    initialValue: item.location_id,
                    rules: [
                      { required: true, message: '请输入 location_id' },
                      { max: 256, message: '超出最大长度(256)' },
                    ],
                  },
                ]"
                :allowClear="true"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="employee_number">
              <a-input
                v-decorator="[
                  'employee_number',
                  {
                    initialValue: item.employee_number,
                    rules: [
                      { required: true, message: '请输入 employee_number' },
                      { max: 256, message: '超出最大长度(256)' },
                    ],
                  },
                ]"
                :allowClear="true"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { configUpdate } from "@/apis/system";

export default {
  props: ["visible", "item"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      confirmLoading: false,
      dataForm: null,
    };
  },
  methods: {
    handleConfirm() {
      this.dataForm.validateFields((error, values) => {
        if (error === null) {
          this.confirmLoading = true;
          configUpdate({ id: this.item.id, ...values })
            .then((data) => {
              this.$emit("update", data);
              this.$message.success("编辑成功");
              this.handleCancel();
            })
            .catch((error) => {
              if (error.non_field_errors && error.non_field_errors.length > 0) {
                this.$message.error(error.non_field_errors[0]);
              } else {
                const errorFields = Object.entries(error).reduce((acc, [field, errors]) => {
                  acc[field] = { value: values[field], errors: errors.map((value) => new Error(value)) };
                  return acc;
                }, {});
                this.dataForm.setFields(errorFields);
              }
            })
            .finally(() => {
              this.confirmLoading = false;
            });
        }
      });
    },
    handleCancel() {
      this.$emit("cancel", false);
    },
  },
  mounted() {
    this.dataForm = this.$form.createForm(this);
  },
};
</script>

<style scoped></style>
